import { AuthToken, UserCountResponse, UserResponse } from 'types/interface';
import { AxiosRequestConfig } from 'axios';
import { BaseApi } from './BaseApi';

class UserApi {
  // TODO: Token is required
  getUserDetail = async (id: string, auth?: AuthToken): Promise<UserResponse> => {
    const requestConfig: AxiosRequestConfig = {};
    if (auth?.accessToken) {
      BaseApi.setAuthHeader(requestConfig, auth.accessToken);
    }
    if (auth?.refreshToken) {
      BaseApi.setRefreshTokenInHeader(requestConfig, auth.refreshToken);
    }
    const res = await BaseApi.get<UserResponse>(`/v2/nfyg/users/${id}`, requestConfig);
    return res.data;
  };

  getUserCount = async (type: string): Promise<UserCountResponse> => {
    const res = await BaseApi.get<UserCountResponse>(
      `/v2/nfyg/user-count?type=${type}`
    );
    return res.data;
  };
}

const UserApiClass = new UserApi();
export { UserApiClass as UserApi };
